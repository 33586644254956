import Vue  from 'vue'
import { host } from '@/../config/host.js'

const state = {

}

const actions = {
    code_auth({dispatch, commit}, code){
        return new Promise((resolve, reject) => {
            Vue.axios.post(`${host}/bitrix/code/`, {code: code})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    update_tasks({dispatch, commit}){
        return Vue.axios.get(`${host}/bitrix/update/`)
            .then((response) => {
                return
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
    },

    create({dispatch, commit}, event_id){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`${host}/bitrix/create/${event_id}/`)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}   

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
